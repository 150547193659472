
@import '../mixins';
@import '../variables';

.h1{
  text-align: center;
  display: grid;
  place-items: center;
  color: $light-green;
  font-family: 'Muli ExtraBold';
  font-size : 3rem;

  @include respond-below (md) {
    font-size : 2.5rem;
  }
  @include respond-below (sm) {
    font-size : 2rem;
  }


}

