@import '../mixins';
@import '../variables';

.holder {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  @include respond-below(sm) {
    flex-wrap: wrap;
  }

  button {

    border: none;
    outline: none;
    padding: 0.5rem 2rem;
    border-radius: 0.75rem;
    margin-top: 1.5rem;
    cursor: pointer;
    transition: 0.2s;
    color: #FFF;

    &.webchildcare {
      background: $bright-green;
    }
    &.webriverside {
      background: $pink;
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 2px 4px 4px rgb(0 0 0 / 20%);
    }
  }

}

.mapblock {
  display: grid;
  grid-template-columns: 1fr 3fr;

  grid-template-rows: 1fr auto;
  gap: 2rem;
  grid-template-areas:
    'logo details'
    'mapimage mapimage';

    @include respond-below(sm) {
      grid-template-columns: 1fr;
      grid-template-areas:
      'logo'
      'details'
      'mapimage';
    }
}
.logo {
  grid-area: logo;
  display: flex;
  align-items: center;
  justify-content: centet;

  img {
    max-width: 130px;
    width: 100%;
  }
}
.details {
  grid-area: details;
  p {
    margin: 0;
  }

  a {
    color: white;
  }
}
.mapimage {
  grid-area: mapimage;
  img {
    max-width: 100%;
    border-radius: 0.5rem;
    width: 100%;
    height: auto;
  }
}

