@import '../variables';

.holder {
  color: white;
  width: 100%;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  // Layout
  .row {
    display: flex;
    width: 100%;
    .col {
      flex: 1;

    }
  }
  /* Accordion styles */
  .tabs {
    overflow: hidden;
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    .tablabel {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      left: 0;


      /* Icon */

      &::after {
        content: url('/images/static/heart-arrow.svg');
        width: 0.75em;
        height: 0.75em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    a {
      color: #fff;
      padding: 0;
      margin: 0;
    }
    .tabcontent {
      max-height: 0;
      height: auto;
      padding: 0 2em;
      transition: ease-out all 0.4s;
      font-size: 1.2rem;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      opacity: 0;

      a{
        color: #0EC5C1;
        font-family: "Muli Bold";
        padding: 0.3rem 0;
        transition: 0.2s;
        &:hover{
          color: lighten(#0EC5C1, 10);
        }
      }
    }
    .tabclose {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      cursor: pointer;
    }
  }

  // :checked
  input:checked {
    + .tablabel {
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tabcontent {
      max-height: 300px;
      padding: 0.3em;
      opacity: 1;
    }
  }
}

.standardLink {

    font-family: "Muli ExtraLight";
    padding: 0.5rem 0;

    &:hover {
      font-family: "Muli Regular";
    }
}
