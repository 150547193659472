
@import '../variables';

.holder {

    background: darken($blue, 3%);
    width: 100%;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    color: white;
    h3{
        font-size: 1.2rem;
        font-family: "Muli Bold";
    }
    p{
        font-size: 1rem;
    }
}

