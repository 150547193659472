@import '../_mixins';
@import '../variables';

footer.main {
    width: 100%;
    background: $blue;
    color: #FFF;
}

.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 2rem;

     @include respond-below(md) {
     padding: 1rem;
  }
}
