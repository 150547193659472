
@import '../variables';
@import '../mixins';

.fullsheet {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $blue;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
}

.linksContainer {

  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  height: calc(100% - 80px);
  top: 80px;
  padding: 2rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;


  overflow-y: auto;
  overflow-x: hidden;

  ul {
    list-style: none;
    font-size: 1.3rem;
    font-family: "Muli Bold";
    line-height: 2rem;
    padding-left: 0;
    display: flex;
    flex-direction: column;
  }


    scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
  border-radius: 2px;
  border: 1px solid var(--scrollbarBG);
  }


}
