

.socialholder {
  grid-area: socialholder;
  display: flex;
justify-content: center;



  & a {
    width: 62px;
    height: 100%;
display: grid;
place-items: center;
  }

  & img {
    max-width: 40px;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
}
