@import 'variables';
@import 'mixins';

.imageHolder {
  width: 100%;
  position: relative;
  top: 0;
  max-width: 100%;
  text-align: center;
  height: auto;
  img {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: 35% 60%;
    min-height: 300px;
    max-height: 680px;
    display: block;
  }
}

.container {
  max-width: 1440px;
  min-height: 400px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  padding: 2rem;

  @include respond-below(sm) {
    position: relative;
    max-width: calc(100% + 25px);
    width: calc(100% + 25px);
    padding: 0;
    min-height: unset;
    max-width: 100%;
    width: 100%;
    .contentPanel {
      max-width: calc(100% + 25px);
      width: calc(100% + 25px);
      min-height: unset;
      background-image: none !important;
      background-color: $light-green;

    }
  }
}

.contentPanel {
  max-width: 450px;
  width: 100%;
  text-align: left;
  min-height: 410px;
  height: auto;
  position: relative;
  left: 0;
  bottom: 20px;
  background-size: 100% 100%;
  padding: 1rem 3em 2rem;
  h2,
  p {
    color: #fff;
    font-size: 1.5rem;
  }

  h2 {
    font-family: 'Rubik Black';
    text-transform: uppercase;
    line-height: 1.2em;
  }

  p {
    font-size: 0.9rem;
  }

  button {
    background: $white;
    color: $white;
    border: none;
    outline: none;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    padding: 0.4rem 2.5rem;
    cursor: pointer;
    margin-top: 1.3rem;
    transition: 0.2s;
    margin-right: 1rem;
    height: 50px;
    width: 100%;
    position: relative;
    z-index: 0;
    font-family: 'Muli Bold';

    &:hover {
      background: #000;
      color: $white;
      transform: scale(1.05);
      box-shadow: 2px 4px 4px rgb(0 0 0 / 10%);
    }

    &:before {
      content: '';
      background: linear-gradient(45deg, $light-blue, $pink, $light-purple, $blue-grey);
      position: absolute;
      top: 0px;
      left: 0px;
      background-size: 400%;
      z-index: -1;
      opacity: 0.9;
      //   filter: blur(5px);
      width: 100%;
      height: 100%;
      animation: glowing 20s linear infinite;
      transition: opacity 0.3s ease-in-out;
      border-radius: 0.8rem;
      &:hover {
        opacity: 0.9;
      }
    }

    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 10px;
      &:hover {
        background: white;
      }
    }
  }
}

// .leadingImage{

//
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.leadingImage {
  min-height: 680px !important;
  @include respond-below(sm) {
    min-height: 450px !important;
  }
}
