@import '../variables';
@import '../mixins';


.maincontainer {
    width: 100%;
    height: 150px;
    background: url('/images/static/more2-illustration.png') no-repeat;
background-position: calc(50% - 250px) 0%;

@supports (background-image: -webkit-image-set(url('/images/static/more2-illustration.webp') 1x)) {
    & { background-image: -webkit-image-set(url('/images/static/more2-illustration.webp') 1x); }
    }

@include respond-below(md) {
    background-position: 50% 0%;

}
}
