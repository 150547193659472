@import '../_mixins';

.holder {
  display: grid;
  grid-template-columns: 1fr 0.2fr 1.5fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'IntroSocial Seperator ContactDetails';


@include respond-below(md) {
  .vertseperator {
    display: none;
  }

  grid-template-areas: 'IntroSocial IntroSocial IntroSocial'
  'ContactDetails ContactDetails ContactDetails';

}


  p {
    color: white;
  }
}
.IntroSocial {
  grid-area: IntroSocial;
}
.ContactDetails {
  grid-area: ContactDetails;
}

.vertseperator {
  grid-area: Seperator;
  position: relative;
  height: 100%;
  width: 1px;
  margin: 0 auto;

  &::after {
    content: '';
    height: 70%;
    border-right: 2px solid white;
    position: absolute;
    top: 15%;
  }
}
