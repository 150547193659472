@import '../variables';
@import '../mixins';

.flexblocks {
    display: flex;
    justify-content: center;
    gap: 2rem;

    min-width: 200px;
    flex-direction: row;
    flex-wrap: wrap;
    padding-inline-start: 40px;
    margin: 0;
    padding: 0;

    img {
      max-width: 100%;
      height: auto;
      border-radius: 0.75rem;
      width: 150px;
      margin:0 auto;
    }

    li {
      display: grid;
      flex-basis:1;

      width: calc(100% / 3 - 1.4rem);

      @include respond-below(md) {

        width: calc(100% / 2 - 1rem);
      }
      @include respond-below(xs) {
        width: 100%;
      }
      min-width: 200px;

      position: relative;
      align-items: start;
    }
  }
