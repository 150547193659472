
@import '../_variables';
@import '../_mixins';

div.copyright {
    width: 100%;
    background: $blue;
    color: #FFF;
    box-shadow: inset 0 6px 8px rgba(black, 0.3);



 > p {
     max-width: 1440px;
     margin: 0 auto;
     padding: 1.5rem 2rem;
     font-size: 0.95rem;
 }

 a{
transition: 0.2s;
    color: #fff;
     text-decoration: none;
     &:hover {
        color: $pink;

     }

 }
}


