
.imgclass {
    max-width: 100%;
    display: block;
    border-radius: 1rem;
}

.sectionHeading {
    font-size: 1.5rem;
}

