@import '../_mixins';

.firstblock {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 1rem;
  grid-template-areas:
    'logo1 socialholder socialholder'
    'desc desc desc';

  @include respond-below(xs) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'logo1'
      'socialholder'
      'desc';
  }
}

.logo1 {
  grid-area: logo1;
  img {
    max-width: 160px;
  }

  @include respond-below(md) {
    text-align: center;
  }
}
.desc {
  grid-area: desc;
}
