
@import '../variables';
@import '../mixins';

.quoteholder {

    padding: 2rem;
    text-align: center;
    position: relative;
    background: $light-green url('/images/static/more2-illustration.png') no-repeat;

    @supports (background-image: -webkit-image-set(url('/images/static/more2-illustration.webp') 1x)) {
    & { background-image: -webkit-image-set(url('/images/static/more2-illustration.webp') 1x); }
    }

    background-position: calc(100% + 400px) 100%;

    @include respond-below(sm) {
        background: $light-green;
    }

    &:before {
        content: '';
        background: url('/images/static/blue-repeat-pattern.svg');
        background-repeat: repeat-x;
        transform: rotateZ(180deg);
        position:absolute;
        top: -10px;
        left: 0;
        height: 10px;
        width: 100%;

    }

    &:after {
        content: '';
        background: url('/images/static/blue-repeat-pattern.svg');
        background-repeat: repeat-x;
        position:absolute;
        bottom: -10px;
        left: 0;
        height: 10px;
        width: 100%;
    }

    p{
        width: 100%;
        max-width: 400px;
        font-family: 'Muli Bold';
        color: white;
        margin: 1rem auto;
        font-size: 1.1rem;
    }
}
