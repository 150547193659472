@import '../variables';
@import '../mixins';

.flexblocks {
  display: flex;
  justify-content: center;
  gap: 1rem 2rem;

  min-width: 200px;
  flex-direction: row;
  flex-wrap: wrap;
  padding-inline-start: 40px;
  margin: 0;
  padding: 0;

  li {
    display: grid;
    flex-basis: 1;

    width: calc(100% / 3 - 1.4rem);

    @include respond-below(md) {
      width: calc(100% / 2 - 1rem);
    }
    @include respond-below(xs) {
      width: 100%;
    }
    min-width: 200px;

    position: relative;
    align-items: start;
  }
}

.imgclip {
  width: 100%;
  overflow: hidden;
  border-radius: 0.75rem;

  img {
    max-width: 100%;
    height: auto;
    width: 100%;
    border-radius: 0.75rem;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.button {
  margin-top: 1rem;
  background: $blue;
  padding: 0.5rem 2rem;
  border-radius: 0.8rem;
  color: white;
  border: 0;
  outline: 0;
  width: 200px;
  align-self: flex-end;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    box-shadow: 2px 4px 4px rgb(0 0 0 / 20%);
  }
}
