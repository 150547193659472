

@import '../_variables';

.button{
    background: $blue;
    position: relative;
    border-radius: 0.5rem;
    padding-bottom: 0.15rem;
    max-width: 45px;
    z-index: 15;
    margin-top: 8px;
}
