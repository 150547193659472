@import '../variables';

.secondblock {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  > div {
    width: auto;
    position: relative;
  }
}
.heading {
  display: flex;
  justify-content: space-around;
}

.button {
  display: flex;
}

.links {
  display: flex;
}

.contactlinks {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  button {
    background: $light-green;
    color: white;
    border: none;
    outline: none;
    border-radius: 0.75rem;
    font-size: 1rem;
    padding: 0.5rem 2.5rem;

    cursor: pointer;
    transition: 0.2s;
    transition-property: transform, background, box-shadow;

    &:hover {
      transform: scale(1.05);
      background: lighten($light-green, 5%);
      box-shadow: 2px 4px 4px rgba(black, 0.2);
    }
  }
  @include respond-below(md) {
    flex-direction: column;
  }
}

.footernavlinks {
  width: 100%;
  max-width: 510px;
  ul {
    display: flex;
    flex-wrap: initial;
    @include respond-below(md) {
    flex-wrap: wrap;
  }
    padding-inline-start: 0px;
    padding: 0px;
    margin-top: 2rem;
    position: relative;
    border-top: 1px solid white;

    li {
      padding: 0.25rem 3.5% 0.25rem 0;
      margin-right: 3.5%;
      margin-top: 20px;
      list-style: none;
      position: relative;

      a{
        color: #FFF;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      &:first-child {
        padding-left: 0;
      }

      &::after {
        content: '|';
        position: absolute;
        right: -2px;
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
}
