@import '../variables';
@import '../mixins';

ul.flexblocks {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  position: relative;
  padding-inline-start: 0px;
  margin: 0;
  padding: 0;

  a{
    text-align: center;
  }

  img {
    max-width: 150px;
height: auto;
    transition: 0.2s;
    &:hover {
      transform: scale(1.05);
      //  filter: drop-shadow(2px 4px 4px rgba(black, 0.2));
    }
  }
  li {
    margin-top: 2rem;
    display: grid;
    flex-basis: 1;

    width: calc(100% / 5 - 3rem);

    @include respond-below(md) {
      width: calc(100% / 3 - 2rem);
    }
    @include respond-below(sm) {
      width: calc(100% / 2 - 2rem);
    }
    @include respond-below(xs) {
      width: 100%;
    }
    min-width: 200px;
    position: relative;
    align-items: start;
  }

  button {
    align-self: end;
    justify-self: center;

    background: $light-blue;
    color: white;
    border: none;
    outline: none;
    border-radius: 0.8rem;
    font-size: 1rem;
    padding: 0.5rem 2.5rem;
    width: 90%;

    cursor: pointer;
    transition: 0.2s;
    transition-property: transform, background, box-shadow;

    &:hover {
      transform: scale(1.05);
      background: lighten($light-blue, 5%);
      box-shadow: 2px 4px 4px rgba(black, 0.2);
    }
  }

  p {
    text-align: center;
    font-size: 0.9rem;
  }
}
