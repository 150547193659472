@import '../variables';
@import '../mixins';

.flexblocks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 2rem;
    min-width: 200px;
    flex-direction: row;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    margin: 0;
    padding: 0;

    @include respond-below(sm) {
      grid-template-columns: 1fr;
    }

    img {
      max-width: 100%;
      height: auto;
      width: 100%;

      border-radius: 1rem 1rem 0 0;
      display: block;
      // border: 5px solid #74A2BE;
    }

    li {
      display: grid;
      grid-template-rows: auto 1fr auto;
      position: relative;

      min-width: 200px;

      align-items: start;
    }
  }

  .copyBlock {

    padding: 2rem;
    height: 100%;
    color: #FFF;
    border-radius: 0 0 1rem 1rem;
    position: relative;
  }
