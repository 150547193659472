@import 'variables';

.holder {
    background: $pink;
    width: 100%;
    height : auto;
    padding: 0.75rem 2rem;
    color: #FFF;
    font-size: 1rem;
    box-shadow: $standard-panel-box-shadow;
    position: relative;

}
