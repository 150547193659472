@import '../mixins';
@import '../variables';




.navHeader {
  margin: 0 auto;
  font-size: 25px;
  background-size: 20px;
  background-repeat: repeat-x;
  position: absolute;
  width: 100%;
  max-width: 1400px;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}

.navHeader a {
  color: rgb(75, 95, 75);
  display: inline-block;
  padding-top: 10px;
  text-decoration: none;
}

.leftLinks {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  z-index: 9;
  margin-left: 20px;
  margin-top: 10px;

  button {
    background: $blue;
    color: white;
    border: none;
    outline: none;
    border-radius: 0.75rem;
    font-size: 1rem;
    padding: 0.3rem 2rem 0.4rem 2rem;
    cursor: pointer;
    transition: 0.2s;
    transition-property: transform, background, box-shadow;
    max-height: 35px;
    &:hover {
      transform: scale(1.05);
      background: lighten($blue, 5%);
      box-shadow: 2px 4px 4px rgba(black, 0.2);
    }
  }
  @include respond-below(sm) {
    display: none;
  }
}

.more2_logo {
  position: absolute;
  top: 1rem;
  right: 2rem;
  max-width: 120px;
  transition: 0.2s;
  transition-property: transform, filter;
  z-index: 15;
  &:hover {
    transform: scale(1.05);
    filter: drop-shadow(2px 4px 4px rgba(black, 0.2));
  }
  @include respond-below(sm) {
    max-width: 80px;
  }
}
