@import '../styles/variables';
@import '../styles/mixins';

.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

.main {
  top: 100px;
  position: relative;
  padding: 5rem 0;
}

.mainContent {
  width: 100%;
  position: relative;
}

.imgclass {
  display: block;
  max-width: 100%;
  height: auto;
}

.latestnewscard {
  min-width: 300px;
}

.buttonStyler {
  max-width: 340px;
  button {
    background: $white;
    color: $white;
    border: none;
    outline: none;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    padding: 0.4rem 2.5rem;
    cursor: pointer;
    margin-top: 1.3rem;
    transition: 0.2s;
    margin-right: 1rem;
    height: 50px;
    width: 100%;
    position: relative;
    z-index: 0;
    font-family: 'Muli Bold';

    &:hover {
      background: #000;
      color: $white;
      transform: scale(1.05);
      box-shadow: 2px 4px 4px rgb(0 0 0 / 10%);
    }

    &:before {
      content: '';
      background: linear-gradient(45deg, $light-blue, $pink, $light-purple, $blue-grey);
      position: absolute;
      top: 0px;
      left: 0px;
      background-size: 400%;
      z-index: -1;
      opacity: 0.9;
      //   filter: blur(5px);
      width: 100%;
      height: 100%;
      animation: glowing 20s linear infinite;
      transition: opacity 0.3s ease-in-out;
      border-radius: 0.8rem;
      &:hover {
        opacity: 0.9;
      }
    }

    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 10px;
      &:hover {
        background: white;
      }
    }
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
}
